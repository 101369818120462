export default {
    zh:{
        customer:{
            queryParam:{
                name:'名称',
                name_placeholder:'请输入名称',
                companyName:'公司名称',
                companyName_placeholder:'请输入公司名称',
                countryId:'国家',
                countryId_placeholder:'请选择国家',
            },
            columns:{
                F_NAME:'客户名称',
                F_COUNTRY_ID:'国家',
                F_CONTACT:'联系号码',
                F_EMAIL:'邮箱',
                F_COMPANY_NAME:'公司名称',
                F_CONTACT_ADDRESS:'联系地址',
            },
            form:{
                name:'名称',
                name_placeholder:'请输入名称',
                name_rule: '请输入名称',
                countryId:'国家',
                contact:'联系号码',
                contact_placeholder:'请输入联系号码',
                email:'邮箱地址',
                email_placeholder:'请输入邮箱地址',
                companyName:'公司名称',
                companyName_placeholder:'请输入公司名称',
                contactAddress:'联系地址',
                contactAddress_placeholder:'请输入联系地址',
            },
            CustomerHdgs:{
                F_NAME:'货代公司名称',
                F_NAME_PLACEHOLDER:'请输入货代公司名称',
                F_CONTACT:'联系方式',
                F_CONTACT_PLACEHOLDER:'请输入联系方式',
                F_ADDRESS:'货代公司地址',
                F_ADDRESS_PLACEHOLDER:'请输入货代公司地址',
                F_LOAD_ADDRESS:'装货地址',
                F_LOAD_ADDRESS_PLACEHOLDER:'请输入装货地址',
            },
            CustomerAccount:{
                F_TYPE:'账号类型',
                F_TYPE_PLACEHOLDER:'请选择账号类型',
                F_CODE:'账号',
                F_STATUS:'状态',
                F_STATUS_PLACEHOLDER:'请选择状态',
            },
            CustomerDelivery:{
                F_NAME:'收货人名称',
                F_NAME_PLACEHOLDER:'请输入收货人名称',
                F_CONTACT:'联系方式',
                F_CONTACT_PLACEHOLDER:'请输入联系方式',
                F_XHGK:'卸货港口',
                F_XHGK_PLACEHOLDER:'请输入卸货港口',
                F_ADDRESS:'收货地址',
                F_ADDRESS_PLACEHOLDER:'请输入收货地址',
            },
        }
    },
    en:{
        customer:{
            queryParam:{
                name:'名称',
                name_placeholder:'请输入名称',
                companyName:'公司名称',
                companyName_placeholder:'请输入公司名称',
                countryId:'国家',
                countryId_placeholder:'请选择国家',
            },
            columns:{
                F_NAME:'名称',
                F_COUNTRY_ID:'国家',
                F_CONTACT:'联系号码',
                F_EMAIL:'邮箱地址',
                F_COMPANY_NAME:'公司名称',
                F_CONTACT_ADDRESS:'联系地址',
            },
            form:{
                name:'名称',
                name_placeholder:'请输入名称',
                name_rule: '请输入名称',
                countryId:'国家',
                contact:'联系号码',
                contact_placeholder:'请输入联系号码',
                email:'邮箱地址',
                email_placeholder:'请输入邮箱地址',
                companyName:'公司名称',
                companyName_placeholder:'请输入公司名称',
                contactAddress:'联系地址',
                contactAddress_placeholder:'请输入联系地址',
            },
            CustomerHdgs:{
                F_NAME:'货代公司名称',
                F_NAME_PLACEHOLDER:'请输入货代公司名称',
                F_CONTACT:'联系方式',
                F_CONTACT_PLACEHOLDER:'请输入联系方式',
                F_ADDRESS:'货代公司地址',
                F_ADDRESS_PLACEHOLDER:'请输入货代公司地址',
                F_LOAD_ADDRESS:'装货地址',
                F_LOAD_ADDRESS_PLACEHOLDER:'请输入装货地址',
            },
            CustomerAccount:{
                F_TYPE:'账号类型',
                F_TYPE_PLACEHOLDER:'请选择账号类型',
                F_CODE:'账号',
                F_STATUS:'状态',
                F_STATUS_PLACEHOLDER:'请选择状态',
            },
            CustomerDelivery:{
                F_NAME:'收货人名称',
                F_NAME_PLACEHOLDER:'请输入收货人名称',
                F_CONTACT:'联系方式',
                F_CONTACT_PLACEHOLDER:'请输入联系方式',
                F_XHGK:'卸货港口',
                F_XHGK_PLACEHOLDER:'请输入卸货港口',
                F_ADDRESS:'收货地址',
                F_ADDRESS_PLACEHOLDER:'请输入收货地址',
            },
        }
    }
}
import CustomerCard from './CustomerCard.vue';
import {reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance,nextTick} from 'vue';
import CustomerListUtil,{ICustomerListDataObj} from './customerListUtil';
import language from './customerLanguage'
export default defineComponent ({
    name: 'CustomerList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:ICustomerListDataObj=reactive<ICustomerListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelComp: CustomerCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/customer/pageData')
            },
            otherParams:{
                countryData:[],//国家下拉数据集
                allAccountData:[],//所有客户账号集合
                customerAccountData:[],//某个指定客户的账号集合
                visible:false,//客户账号弹出框
                accountTbParams:{
                    canPage:false,
                    autoLoad:false,
                    multiple:true,
                    staticData:true
                }
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new CustomerListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'countryId'==params.comboId){
                    return dataObj.otherParams.countryData
                }
            }
        })

        const formatPageInfo=(options:any)=>{
            return options;
        }
        //重置密码
        const resetPwdHandler=async ()=>{
            let selectRows = dataObj.pageListRef.getTbInst().getSelection();
            if (selectRows.length == 0) {
                proxy.$message('请选择要重置密码的客户');
                return;
            }
            await doReset(selectRows)
        }
        const doReset=async (selectRows:any)=>{
            await dataObj.utilInst.getCustomerAccount(selectRows[0]['F_ID']);
            dataObj.otherParams.visible=true;
            nextTick(()=>{
                dataObj.refMap.get('accountRef2').setTbData(dataObj.otherParams.customerAccountData)
            })
        }
        const sureHandler=async ()=>{
            let selectRows=dataObj.refMap.get('accountRef2').getSelection();
            if (selectRows.length == 0) {
                proxy.$message('请选择要重置密码的账号');
                return;
            }
            const loading = proxy.$loading({lock: true,text: proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});

            selectRows = selectRows.map((item:any)=>item.id);
            let res = await utils.OrderProviderApi.resetCustomerAccount({params:{ids:selectRows}});
            if(res.result){
                dataObj.otherParams.visible=false;
                utils.Tools.success();
            }
            loading.close();
        }
        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo,resetPwdHandler,sureHandler
        }
    }
});